import {createContext, useContext} from 'react';
import {UserInfo} from "Typings/Api/Users";

export interface AuthContextState {
  isLoggedIn: boolean;
  setIsLoggedIn: (v: boolean) => void,
  userInfo: UserInfo | undefined;
  setUserInfo: (v: UserInfo | undefined) => void,
  logout: () => void,
}

export const AuthContext = createContext<AuthContextState>({} as AuthContextState);

export function useAuthContext(): AuthContextState {
  return useContext(AuthContext);
}

import * as anchor from '@project-serum/anchor';
import * as spl from '@solana/spl-token';
import {TOKEN_PROGRAM_ID} from '@solana/spl-token';
import {
  Connection,
  PublicKey,
} from '@solana/web3.js';
// @ts-ignore
import {sign} from "tweetnacl";
import bs58 from "bs58";
import {AnchorWallet} from "@solana/wallet-adapter-react/src/useAnchorWallet";
import {IDL} from "Constants/types";
import {NftAddressData} from "../../Typings/Components/NftCard";

export const getAtaForMint = async (
  mint: anchor.web3.PublicKey,
  buyer: anchor.web3.PublicKey,
  program: anchor.Program,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [buyer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    program.programId,
  );
};


// @ts-ignore
export const signThatShit = async (publicKey, signMessage, msg) => {
  try {
    // `publicKey` will be null if the wallet isn't connected
    if (!publicKey) throw new Error('Wallet not connected!');
    // `signMessage` will be undefined if the wallet doesn't support it
    if (!signMessage) throw new Error('Wallet does not support message signing!');

    // Encode anything as bytes
    // @ts-ignore
    const message = new TextEncoder().encode(msg);
    // Sign the bytes using the wallet
    const signature = await signMessage(message);
    // Verify that the bytes were signed using the private key that matches the known public key
    if (!sign.detached.verify(message, signature, publicKey.toBytes())) throw new Error('Invalid signature!');

    return bs58.encode(signature)
  } catch (error: any) {
    throw new Error(error?.message || 'Signing failed');
  }
}

export const getTokensInWallet = async (connection: Connection, wallet: AnchorWallet) => {
  const response = await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {programId: TOKEN_PROGRAM_ID});
  return response.value
    .filter(o => o.account?.data?.parsed?.info?.tokenAmount?.amount >= 0)
    .map(o => o.account?.data?.parsed?.info?.mint)
    .filter(Boolean);
}


export const ETHEREAL_BLADE = new anchor.web3.PublicKey(process.env.REACT_APP_ETHEREAL_BLADE as string);
export const DEPOSIT_WALLET = new anchor.web3.PublicKey(process.env.REACT_APP_DEPOSIT_WALLET as string);

function getProvider(connection: Connection, wallet: AnchorWallet) {
  return new anchor.AnchorProvider(
    connection, wallet, {preflightCommitment: "processed"},
  );
}

function getProgram(connection: Connection, wallet: AnchorWallet) {
  const provider = getProvider(connection, wallet);
  return new anchor.Program(IDL as anchor.Idl, "EBLkZvtNWGNXsjqLPGrHtuhMrbJAnQaXoyXQz8b2hcc", provider);
}

async function findDepositTokenAccount(
  mint: PublicKey,
  authority: PublicKey,
  etherealBlade: PublicKey,
  program: anchor.Program,
) {
  return await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("deposit_token_account"),
      mint.toBuffer(),
      authority.toBuffer(),
      etherealBlade.toBuffer(),
    ],
    program.programId,
  );
}

export const stakeToken = async (connection: Connection, wallet: AnchorWallet, nfts: NftAddressData[]) => {
  const program = getProgram(connection, wallet);

  await Promise.all(
    nfts.map(async nft => {
      const mint = await spl.getMint(
        connection,
        new anchor.web3.PublicKey(nft.mint),
      );
      const [depositTokenAccount] = await findDepositTokenAccount(
        mint.address,
        wallet.publicKey,
        ETHEREAL_BLADE,
        program
      );
      const stakerTokenAccount = await spl.getAssociatedTokenAddress(
        mint.address,
        wallet.publicKey,
      );

      const firstStakeTx = await program.methods
          .stake(
              new anchor.BN(nft.multiplier),
              nft.universe,
              new anchor.BN(nft.drop)
          )
          .accounts({
              etherealBlade: ETHEREAL_BLADE,
              mint: mint.address,
              staker: wallet.publicKey,
              depositWallet: DEPOSIT_WALLET,
              stakerTokenAccount,
              depositTokenAccount,
              rent: anchor.web3.SYSVAR_RENT_PUBKEY,
              tokenProgram: spl.TOKEN_PROGRAM_ID,
              systemProgram: anchor.web3.SystemProgram.programId
          })
          .rpc()

      console.log(`Stake transaction signature - [${firstStakeTx}]`);
    })
  )

  return true;
}

export const unstakeToken = async (connection: Connection, wallet: AnchorWallet, nfts: string[]) => {
  const program = getProgram(connection, wallet);

  await Promise.all(
    nfts.map(async nft => {
      const mint = await spl.getMint(
        connection,
        new anchor.web3.PublicKey(nft),
      );
      const [depositTokenAccount] = await findDepositTokenAccount(
        mint.address,
        wallet.publicKey,
        ETHEREAL_BLADE,
        program
      );
      const stakerTokenAccount = await spl.getAssociatedTokenAddress(
        mint.address,
        wallet.publicKey,
      );

      const firstUnstakeTx = await program.methods
          .unstake()
          .accounts({
              etherealBlade: ETHEREAL_BLADE,
              mint: mint.address,
              staker: wallet.publicKey,
              depositWallet: DEPOSIT_WALLET,
              stakerTokenAccount,
              depositTokenAccount,
              tokenProgram: spl.TOKEN_PROGRAM_ID,
              systemProgram: anchor.web3.SystemProgram.programId
          })
          .rpc()

      console.log(`Unstake transaction signature - [${firstUnstakeTx}]`);
    })
  )

  return true;
}

export const grabToken = async (connection: Connection, wallet: AnchorWallet, nfts: string[]) => {
  const program = getProgram(connection, wallet);

  await Promise.all(
    nfts.map(async nft => {
      const mint = await spl.getMint(
        connection,
        new anchor.web3.PublicKey(nft),
      );
      const grabTx = await program.methods
          .grab()
          .accounts({
              etherealBlade: ETHEREAL_BLADE,
              mint: mint.address,
              staker: wallet.publicKey,
              depositWallet: DEPOSIT_WALLET,
              systemProgram: anchor.web3.SystemProgram.programId
          })
          .rpc()

      console.log(`Grab transaction signature - [${grabTx}]`);
    })
  )

  return true;
}

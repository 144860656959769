import React, {useCallback, useEffect, useState} from 'react';
import {useAnchorWallet, useConnection} from "@solana/wallet-adapter-react";
import {grabToken, stakeToken, unstakeToken} from "Utils/Solana/publicKey";
import LoadingSpinner from "Components/LoadingSpinner";
import {StakingsApi} from "Api/Stakings";
import {joinClasses} from "Utils";
import {defaultNftData, NftAddressData, NftCardInfo, NftMetaInfo} from "Typings/Components/NftCard";
import LoadingButton from "Components/LoadingButton";

import 'Styles/NftCard.scss';

interface NftCardProps {
  card: NftCardInfo,
  setIsPending: Function,
  isPending: boolean,
}

const NftCard: React.FC<NftCardProps> = ({card, setIsPending, isPending}) => {
  const [picIsLoading, setPicIsLoading] = useState(false);
  const isStaked = card.status === 'STAKING';
  const [stakingId, setStakingId] = useState<NftCardInfo['staking_id']|undefined>(card.staking_id);
  const [meta, setMeta] = useState<NftMetaInfo>();
  const wallet = useAnchorWallet();
  const {connection} = useConnection();

  const handleOnStake = useCallback(async () => {
    if (!wallet) return Promise.reject();
    try {
      const metaAttr = meta?.attributes;
      let tokenData = {
        ...defaultNftData,
        mint: card.nft_address,
      } as NftAddressData;
      if (metaAttr) {
        tokenData = metaAttr.reduce((acc: NftAddressData, attr) => {
          if (!attr?.trait_type) return acc;
          switch (attr.trait_type.toLowerCase()) {
            case 'drop':
              if (attr.value) acc.drop = parseFloat(attr.value);
              break;
            case 'multiplier':
              if (attr.value) acc.multiplier = parseFloat(attr.value);
              break;
            case 'family':
              if (attr.value) acc.family = attr.value;
              break;
            case 'universe':
              if (attr.value) acc.universe = attr.value;
              break;
          }

          return acc;
        }, tokenData);
      }

      await stakeToken(
        connection,
        wallet,
        [tokenData],
      );

      const api = new StakingsApi();
      const {data: { staking_id }} = await api.create(card.nft_address);
      setStakingId(staking_id);
      setIsPending(true);
      window.location.reload();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [wallet, connection, card?.nft_address, meta?.attributes, setIsPending]);

  const handleOnGrab = useCallback(async () => {
    if (!wallet) return Promise.reject();
    try {
      await grabToken(
        connection,
        wallet,
        [card.nft_address],
      );
      window.location.reload();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [wallet, connection, card?.nft_address]);

  const handleOnUnstake = useCallback(async () => {
    if (!wallet) return Promise.reject();
    try {
      await unstakeToken(
        connection,
        wallet,
        [card.nft_address],
      );
      if(stakingId) {
        const api = new StakingsApi();
        await api.unstake(stakingId);
        setStakingId(undefined);
      }
      setIsPending(true);
      window.location.reload();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [wallet, connection, stakingId, card?.nft_address, setIsPending]);

  useEffect(() => {
    (async () => {
      if (!card.meta_url || picIsLoading || typeof meta !== 'undefined') return;
      try {
        setPicIsLoading(true);
        const result: NftMetaInfo = await fetch(card.meta_url)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          });
        setMeta(result);
        setPicIsLoading(false);
      } catch (e) {
        console.log(e);
        setPicIsLoading(false);
      }
    })()
  }, [picIsLoading, meta, card.meta_url])

  return (
    <div
      className={joinClasses([
        'nft-card', 'nft-card_rounded', 'w-100',
        isStaked ? 'nft-card_stacked' : '',
        isPending ? 'nft-card_pending' : ''
      ])}
    >
      {picIsLoading
        ? (
          <div className="nft-card__image d-flex align-items-center justify-content-center w-100 color-success" style={{ height: 337 }}>
            <LoadingSpinner />
          </div>
        )
        : (meta?.image ? (
          <img
            className="nft-card__image w-100"
            height="337"
            src={meta?.image || "/assets/nft-card-avatar.jpg"}
            alt={meta?.name}
          />
        ) : null)}

      <div className="nft-card__info">
        <div className="d-flex flex-wrap align-items-center justify-content-center">
          <p className="mb-0"><b>ID:</b> #{card.nft_id}</p>
        </div>
      </div>

      <div className="nft-card__actions d-flex align-items-center justify-content-center">
        { process.env.REACT_APP_ENABLE_GRAB === 'true' && isStaked
          ? <LoadingButton className="btn-violet" onClick={handleOnGrab} disabled={!wallet}>Grab</LoadingButton>
          : <LoadingButton className="btn-success" onClick={handleOnStake} disabled={!wallet}>Stake</LoadingButton>
        }
        <LoadingButton className="btn-error ms-2" onClick={handleOnUnstake} disabled={!wallet}>Unstake</LoadingButton>
      </div>
    </div>
  );
}

export default NftCard;
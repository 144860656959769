import React, {useEffect, useState} from 'react';
import {discordIcon, twitterIcon} from "Assets/Icons";
import {formatNumber, joinClasses} from "Utils";
import {StakingsApi} from "Api/Stakings";
import {LAMPORTS_PER_SOL} from '@solana/web3.js';
import {FLOOR_PRICE} from "Constants";
import {discord, twitter} from "Constants/socialLinks";

import "Styles/PromoBlock.scss";


interface PromoBlockProps {
  className?: string,
}
interface StatsData {
  minValueLocked: number,
  totalStaked: number,
  percentOfStaked: number,
}

function PromoBlock({className}: PromoBlockProps) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [stats, setStats] = useState<StatsData>();

  useEffect(() => {
    (async () => {
      if (isLoading || typeof stats !== 'undefined') return;
      setIsLoading(true);
      try {
        const api = new StakingsApi();
        const { meta: { count } } = await api.count();
        const statsData: StatsData = {
          minValueLocked: FLOOR_PRICE / LAMPORTS_PER_SOL * (count as number),
          totalStaked: (count as number),
          percentOfStaked: ((count as number) / 720) * 100
        }
        setStats(statsData);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isLoading, stats]);

  return (
    <div className={joinClasses(["pt-4", className])}>
      <div className="container px-0 pt-3 position-relative">
        <div className="d-flex justify-content-center">
          <div className="promo-block text-center">
            <h1 className="promo-block__title mb-1">MetaBuilders</h1>
            <p className="promo-block__subtitle">Stake your MetaBuilders</p>

            <div className="navbar__social-icon-wrapper d-block d-sm-none mb-3" style={{marginRight: 20}}>
              <a href={discord} className="navbar__social-icon" style={{marginRight: 10}} target="_blank" rel="noreferrer">{discordIcon}</a>
              <a href={twitter} className="navbar__social-icon" target="_blank" rel="noreferrer">{twitterIcon}</a>
            </div>

            <div className="mt-4 pt-2">
              <div className="row stacking-info">
                <div className="col-12 col-sm-auto">
                  <p className="stacking-info__title mb-1 text-uppercase">Minimum Value Locked</p>
                  <p className="stacking-info__count">{formatNumber.format(stats?.minValueLocked || 0)} SOL</p>
                </div>
                <div className="col-12 col-sm-auto">
                  <p className="stacking-info__title mb-1 text-uppercase">Total Staked</p>
                  <p className="stacking-info__count">{formatNumber.format(stats?.totalStaked || 0)}</p>
                </div>
                <div className="col-12 col-sm-auto">
                  <p className="stacking-info__title mb-1 text-uppercase">% of Staked</p>
                  <p className="stacking-info__count">{formatNumber.format(stats?.percentOfStaked || 0)}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlock;
import {BaseApi} from "Api/BaseApi";
import {AxiosRequestConfig} from "axios";

import {NftInfo as Data} from 'Typings/Api/Nfts';
import {UserInfo} from "Typings/Api/Users";
import {
  createExtractInterceptor,
  createExtractErrorInterceptor
} from "Api/interceptors";
import {
  BaseApiReturnValueCollection as BRC,
} from "Typings/common";

export class NftsApi extends BaseApi {
  protected readonly endpoint = 'nfts';
  protected readonly endpointPrefix = 'v1';

  protected setInterceptors() {
    this.http.interceptors.response.use(
      createExtractInterceptor(),
      createExtractErrorInterceptor(),
    )
  }

  public getList(user_id: UserInfo["user_id"], config?: AxiosRequestConfig) {
    const path = ''
    const [url, params] = this.getUrl(path, {user_id})
    return this.request<BRC<Data>>('GET', url, {params, ...config})
  }
}

import React, {useEffect, useState} from 'react';
import {useAnchorWallet, useConnection, useWallet} from "@solana/wallet-adapter-react";
import {useTokenLists} from "Store/NftListContext/useTokenLists";
import {useAuthContext} from "Store/AuthContext/useAuthContext";
import NftCard from "Components/NftCard";
import {NftsApi} from "Api/Nfts";
import {StakingsApi} from "Api/Stakings";
import {NftCardInfo} from "Typings/Components/NftCard";
import {getTokensInWallet} from "Utils/Solana/publicKey";
import {createSimpleHashForObj} from "Utils";
import unionBy from "lodash/unionBy";
import clone from "lodash/clone";

import 'Styles/NftList.scss'

interface NftListProps {
  innerStyle?: object,
}
enum ListType {
  STAKED = "STAKED",
  UNSTAKED = "UNSTAKED",
}

function NftList({innerStyle}: NftListProps) {
  const {staked, setStaked, pending, setPending, ready, setReady} = useTokenLists();
  const {isLoggedIn, userInfo} = useAuthContext();
  const [ownedTokens, setOwnedTokens] = useState<string[]>();
  const [shouldUpdate, setShouldUpdate] = useState<boolean>();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSLoading, setIsSLoading] = useState(false);
  const [isULoading, setIsULoading] = useState(false);

  const aWallet = useAnchorWallet();
  const {connection} = useConnection();
  const {wallet} = useWallet();
  const isConnected = isLoggedIn && wallet?.adapter?.connected;

  useEffect(() => {
    (async () => {
      if (!isConnected || isError || isLoading || typeof userInfo === 'undefined' || !aWallet || !connection) return;
      setIsLoading(true);
      let tokens: string[] = [];
      if (!ownedTokens) {
        try {
          tokens = await getTokensInWallet(connection, aWallet);
          setOwnedTokens(tokens);
        }
        catch (e) {
          console.error(e)
          setIsError(true);
          return;
        }
      }
      else tokens = ownedTokens;

      if (!isSLoading && typeof staked === 'undefined') {
        try {
          const api = new StakingsApi();
          setIsSLoading(true);

          const {data: result} = await api.getList(userInfo.user_id);
          const list = result
            .filter(o => o.state !== 'REVOKED' && !(o.state === 'CREATION_REQUESTED' && tokens.includes(o.nft_address)))
            .map(o => {
              const info: NftCardInfo = {
                nft_id: o.nft_id,
                nft_address: o.nft_address,
                meta_url: o.nft_metadata,
                staking_id: o.staking_id,
                status: 'STAKING',
              }

              if (o.state !== 'STAKING') info.status = 'PENDING';

              return info;
            });
          setStaked(list.filter(o => o.status === 'STAKING'));
          setPending(list.filter(o => o.status === 'PENDING'));
          setIsSLoading(false);
        } catch (e) {
          setIsSLoading(false);
          setStaked([])
          console.log(e);
        }
      }

      if (!isULoading && typeof ready === 'undefined') {
        try {
          const api = new NftsApi();
          setIsULoading(true);
          const {data: result} = await api.getList(userInfo.user_id);
          setReady(result
            .filter(o => !o.is_unreveal && tokens.includes(o.address))
            .map(o => {
              const info: NftCardInfo = {
                nft_id: o.nft_id,
                nft_address: o.address,
                meta_url: o.meta_url,
                status: 'READY',
              }
              return info;
            })
          );

          setIsULoading(false);
        } catch (e) {
          setIsULoading(false);
          setReady([]);
          console.log(e);
        }
      }

      setIsLoading(false);
    })()
  }, [
    isLoading, isSLoading, isULoading, isError, isConnected, ready, staked, userInfo, connection,
    aWallet, ownedTokens, setPending, setReady, setStaked
  ]);

  useEffect(() => {
    if (shouldUpdate) setShouldUpdate(false);
  }, [shouldUpdate])

  const updatePendingList = (type: ListType, nft_id: number, isPending: boolean) => {
    let list: NftCardInfo[] | undefined, pendingList = clone(pending||[]);
    switch (type) {
      case ListType.STAKED: if (staked?.length) list = clone(staked); break;
      case ListType.UNSTAKED: if (ready?.length) list = clone(ready); break;
    }
    if (list?.length) {
      if (isPending) {
        const cardIndex = list.findIndex(o => o.nft_id === nft_id)
        if (cardIndex >= 0) {
          const card = list.splice(cardIndex, 1)[0];
          card.status = 'PENDING';
          pendingList.push(card);

          setPending(pendingList)
          switch (type) {
            case ListType.STAKED: setStaked(list?.length ? list : undefined); break;
            case ListType.UNSTAKED: setReady(list?.length ? list : undefined); break;
          }
        }
      }
    }
  }

  const charactersImg = (
    <div className="characters-img w-100">
      <img src="/assets/bg-characters.png" alt="" />
    </div>
  )

  if (!isConnected) {
    return (
      <div className="nft-list nft-list_not-authorized">
        {charactersImg}
      </div>
    )
  }

  const stakedAndPending = unionBy((pending || []), (ready?.filter(o => o.status === 'PENDING')), (staked || []), 'nft_id');
  const readyOnly = ready?.filter(o => o.status === 'READY' && !staked?.find(s => s.nft_id === o.nft_id))

  return (
    <div className="nft-list">
      {charactersImg}

      <div className="nft-list__inner" style={innerStyle}>
        <h2 className="text-center text-uppercase mb-4 mb-md-5">Your wallet</h2>

        <div className="container">
          <div className="row pt-2">
            <div className="col-12 col-md-6">
              <p className="text-center text-uppercase ntf-list__column-title mb-4">Staked <b>MetaBuilders ({stakedAndPending?.length || 0})</b></p>
              <div className="d-flex flex-column align-items-center pt-3">
                {isSLoading
                  ? <p>Loading...</p>
                  : (
                    stakedAndPending
                      ?.map((o) =>
                        <NftCard
                          key={`nft-staked-list-item-${createSimpleHashForObj(o)}`}
                          card={o}
                          isPending={o.status === 'PENDING'}
                          setIsPending={updatePendingList.bind(null, ListType.STAKED, o.nft_id)}
                        />)
                  )
                }
              </div>
            </div>
            <div className="col-12 col-md-6 mt-5 mt-md-0">
              <p className="text-center text-uppercase ntf-list__column-title mb-4">Unstaked <b>MetaBuilders ({readyOnly?.length || 0})</b></p>
              <div className="d-flex flex-column align-items-center pt-3">
                {isSLoading
                  ? <p>Loading...</p>
                  : readyOnly?.map((o) =>
                    <NftCard
                      key={`nft-unstaked-list-item-${createSimpleHashForObj(o)}`}
                      card={o}
                      isPending={o.status === 'PENDING'}
                      setIsPending={updatePendingList.bind(null, ListType.UNSTAKED, o.nft_id)}
                    />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NftList;
/* eslint @typescript-eslint/member-ordering: 0 */
/* eslint max-classes-per-file: 0 */
import axios, {
  AxiosRequestConfig,
  Method,
} from 'axios'
import UrlPattern from 'url-pattern'
import omit from 'lodash/omit'

import {
  BaseApiParams,
} from 'Typings/common'
import {
  createExtractInterceptor,
} from 'Api/interceptors'


const URL_PATTERN_OPTIONS = { segmentNameCharset: 'a-zA-Z0-9_-' }

const createAxiosInstance = (baseURL: string|undefined) => {
  return axios.create({
    baseURL,
    timeout: process.env.NODE_ENV === 'production' ? void 0 : 10000,
  })
}

export abstract class BaseApi<
  BP extends BaseApiParams = BaseApiParams,
  > {
  protected abstract readonly endpoint: string;

  constructor(
    protected readonly baseURL: string|undefined = process.env.REACT_APP_API_HOST,
    protected readonly endpointPrefix?: string|undefined,
    protected readonly http = createAxiosInstance(baseURL),
  ) {
    this.setInterceptors()
  }

  protected getUrl(path: string, params?: BP): [string, Partial<BP>, string] {
    let fullUrl = [
      this.endpointPrefix,
      this.endpoint,
      path,
    ].filter(Boolean).join('/')

    fullUrl = fullUrl.replace(/\/\//g, '/')
    let pattern

    try {
      pattern = new UrlPattern(fullUrl, URL_PATTERN_OPTIONS)
    } catch {
      pattern = new UrlPattern(path, URL_PATTERN_OPTIONS)
    }

    const cleanedParams = omit(params || {}, pattern.names)

    try {
      const url = pattern.stringify(params || {})
      return [url, cleanedParams, fullUrl]
    } catch (e) {
      return [fullUrl, cleanedParams, fullUrl]
    }
  }

  protected setInterceptors() {
    this.http.interceptors.response.use(
      createExtractInterceptor(),
    )
  }

  public request<T1, R = T1>(
    method: Method, url: string, config: AxiosRequestConfig = {},
  ): Promise<R> {
    if (!this.endpoint && process.env.NODE_ENV !== 'production') {
      throw new Error(`You must set endpoint in "${this.constructor.name}"`)
    }

    return this.http.request<T1, R>({ ...config, method, url })
  }
}
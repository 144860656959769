export type EtherealBlade = {
  "version": "0.1.0",
  "name": "ethereal_blade",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "maxSupply",
          "type": "u64"
        },
        {
          "name": "lamports",
          "type": "u64"
        }
      ]
    },
    {
      "name": "stake",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "staker",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stakerTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "depositTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "multiplier",
          "type": "u64"
        },
        {
          "name": "universe",
          "type": "string"
        },
        {
          "name": "drop",
          "type": "u64"
        }
      ]
    },
    {
      "name": "process",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "allUniverses",
          "type": {
            "vec": "string"
          }
        },
        {
          "name": "allDrops",
          "type": {
            "vec": "u64"
          }
        }
      ]
    },
    {
      "name": "grab",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "staker",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstake",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "staker",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stakerTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "depositTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "etherealBlade",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "maxSupply",
            "type": "u64"
          },
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "state",
            "type": {
              "defined": "EtherealState"
            }
          },
          {
            "name": "mints",
            "type": {
              "vec": {
                "defined": "EtherealMint"
              }
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "EtherealMint",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "drop",
            "type": "u64"
          },
          {
            "name": "isGrabbed",
            "type": "bool"
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "multiplier",
            "type": "u64"
          },
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "payment",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "universe",
            "type": {
              "option": "string"
            }
          }
        ]
      }
    },
    {
      "name": "EtherealState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Initialized"
          },
          {
            "name": "Processed"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidState",
      "msg": "Invalid state"
    },
    {
      "code": 6001,
      "name": "NotProcessed",
      "msg": "Not processed"
    },
    {
      "code": 6002,
      "name": "NotStaked",
      "msg": "Token not staked"
    },
    {
      "code": 6003,
      "name": "InsufficientLamports",
      "msg": "Not enough lamports"
    },
    {
      "code": 6004,
      "name": "AlreadyGrabbed",
      "msg": "Token already grabbed"
    }
  ]
};

export const IDL: EtherealBlade = {
  "version": "0.1.0",
  "name": "ethereal_blade",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "maxSupply",
          "type": "u64"
        },
        {
          "name": "lamports",
          "type": "u64"
        }
      ]
    },
    {
      "name": "stake",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "staker",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stakerTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "depositTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "multiplier",
          "type": "u64"
        },
        {
          "name": "universe",
          "type": "string"
        },
        {
          "name": "drop",
          "type": "u64"
        }
      ]
    },
    {
      "name": "process",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "allUniverses",
          "type": {
            "vec": "string"
          }
        },
        {
          "name": "allDrops",
          "type": {
            "vec": "u64"
          }
        }
      ]
    },
    {
      "name": "grab",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "staker",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstake",
      "accounts": [
        {
          "name": "etherealBlade",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "staker",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "depositWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stakerTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "depositTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "etherealBlade",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "maxSupply",
            "type": "u64"
          },
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "state",
            "type": {
              "defined": "EtherealState"
            }
          },
          {
            "name": "mints",
            "type": {
              "vec": {
                "defined": "EtherealMint"
              }
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "EtherealMint",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "drop",
            "type": "u64"
          },
          {
            "name": "isGrabbed",
            "type": "bool"
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "multiplier",
            "type": "u64"
          },
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "payment",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "universe",
            "type": {
              "option": "string"
            }
          }
        ]
      }
    },
    {
      "name": "EtherealState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Initialized"
          },
          {
            "name": "Processed"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidState",
      "msg": "Invalid state"
    },
    {
      "code": 6001,
      "name": "NotProcessed",
      "msg": "Not processed"
    },
    {
      "code": 6002,
      "name": "NotStaked",
      "msg": "Token not staked"
    },
    {
      "code": 6003,
      "name": "InsufficientLamports",
      "msg": "Not enough lamports"
    },
    {
      "code": 6004,
      "name": "AlreadyGrabbed",
      "msg": "Token already grabbed"
    }
  ]
};
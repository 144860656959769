

export const discordIcon = (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3702 24.9655C22.9872 25.7264 23.7275 26.6107 23.7275 26.6107C28.0334 26.4743 29.8105 23.809 29.9855 23.5465C29.9952 23.532 30 23.5259 30 23.5259C30 16.9655 27.0386 11.639 27.0386 11.639C24.0977 9.43848 21.2802 9.50017 21.2802 9.50017L20.9923 9.82922C24.4884 10.8781 26.1131 12.4205 26.1131 12.4205C23.9743 11.2688 21.8766 10.693 19.9229 10.4668C18.4422 10.3022 17.0231 10.3434 15.7686 10.5079C15.6609 10.5079 15.5689 10.5236 15.4651 10.5412C15.45 10.5438 15.4347 10.5464 15.419 10.549C14.6992 10.6313 12.9512 10.8781 10.7506 11.8446C9.98972 12.1737 9.53728 12.4205 9.53728 12.4205C9.53728 12.4205 11.2237 10.7958 14.9255 9.74696L14.7198 9.50017C14.7198 9.50017 11.9229 9.43848 8.96144 11.639C8.96144 11.639 6 16.9655 6 23.5259C6 23.5259 7.72751 26.4873 12.2725 26.6313C12.2725 26.6313 13.0334 25.7264 13.6504 24.9449C11.0386 24.1634 10.0514 22.5387 10.0514 22.5387C10.0514 22.5387 10.2571 22.6827 10.6272 22.8884C10.64 22.8884 10.6527 22.8962 10.6702 22.9071C10.6811 22.9138 10.6938 22.9216 10.7095 22.9295C10.7404 22.95 10.7712 22.9655 10.8021 22.9809C10.8329 22.9963 10.8638 23.0117 10.8946 23.0323C11.4087 23.3202 11.9229 23.5464 12.3959 23.7315C13.2391 24.0812 14.2468 24.3896 15.419 24.6159C16.9614 24.9038 18.7712 25.0066 20.7455 24.6364C21.7121 24.4513 22.6992 24.184 23.7275 23.7521C24.4473 23.4848 25.2494 23.094 26.0926 22.5387C26.0926 22.5387 25.0643 24.2045 22.3702 24.9655ZM21.6746 17.0889C20.5024 17.0889 19.5769 18.0967 19.5769 19.3511C19.5769 20.6056 20.5229 21.6134 21.6746 21.6134C22.8468 21.6134 23.7723 20.6056 23.7723 19.3511C23.7723 18.0967 22.8263 17.0889 21.6746 17.0889ZM14.1682 17.0889C12.9959 17.0889 12.0705 18.0967 12.0705 19.3511C12.0705 20.6056 13.0165 21.6134 14.1682 21.6134C15.3404 21.6134 16.2659 20.6056 16.2659 19.3511C16.2864 18.0967 15.3404 17.0889 14.1682 17.0889Z" fill="currentColor"/>
  </svg>
)

export const twitterIcon = (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0771 14.2174C26.0771 14.4088 26.0771 14.6002 26.0771 14.7643C26.0771 20.4244 21.7568 26.9596 13.8818 26.9596C11.4482 26.9596 9.20605 26.2487 7.29199 25.0455C7.62012 25.0729 7.97559 25.1002 8.30371 25.1002C10.2998 25.1002 12.1592 24.4166 13.6357 23.2682C11.749 23.2408 10.1631 21.983 9.64355 20.2877C9.91699 20.3424 10.1631 20.3698 10.4639 20.3698C10.8467 20.3698 11.2295 20.3151 11.585 20.2057C9.61621 19.8229 8.13965 18.1002 8.13965 16.0221C8.13965 15.9948 8.13965 15.9948 8.13965 15.9674C8.71387 16.2955 9.37012 16.4869 10.0811 16.5143C8.93262 15.7487 8.16699 14.4362 8.16699 12.9323C8.16699 12.1393 8.38574 11.401 8.74121 10.7721C10.8467 13.3698 14.0186 15.0651 17.5732 15.2565C17.4912 14.9557 17.4639 14.6002 17.4639 14.2721C17.4639 11.8932 19.3779 9.97913 21.7568 9.97913C22.9873 9.97913 24.1084 10.4987 24.874 11.319C25.8584 11.1276 26.7607 10.7721 27.6084 10.2799C27.2803 11.2916 26.5967 12.1119 25.7217 12.6588C26.5967 12.5494 27.417 12.3307 28.1826 11.9752C27.6357 12.8502 26.9248 13.6158 26.0771 14.2174Z" fill="currentColor"/>
  </svg>
)

export const logoutIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" fill="currentColor" />
  </svg>
)
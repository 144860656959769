import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import * as anchor from "@project-serum/anchor";
import {LAMPORTS_PER_SOL} from "@solana/web3.js";

export function joinClasses(classes: Array<any>): string {
  return classes.filter(Boolean).join(' ')
}

// @ts-ignore
function sortObjectKeys (unordered: object) {
  return Object.keys(unordered).sort().reduce(
    (obj, key) => {
      // @ts-ignore
      if (isArray(unordered[key])) {
        // @ts-ignore
        obj[key] = unordered[key].map(o => sortObjectKeys(o))
      }
      // @ts-ignore
      else if (isObject(unordered[key])) {
        // @ts-ignore
        obj[key] = sortObjectKeys(unordered[key])
      }
      // @ts-ignore
      else obj[key] = unordered[key];
      return obj;
    },
    {}
  );
}

export function createSimpleHashForObj (obj: object) {
  let hash = 0;
  const string = JSON.stringify(sortObjectKeys(obj));
  if (string.length === 0) return hash;
  for(let i = 0; i < string.length; i++) {
    const ch = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + ch;
    hash = hash & hash;
  }

  return hash;
}

const numberFormater = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatNumber = {
  format: (val?: number) => {
    if (!val) {
      return '0';
    }

    return numberFormater.format(val);
  },
  asNumber: (val?: anchor.BN) => {
    if (!val) {
      return undefined;
    }

    return val.toNumber() / LAMPORTS_PER_SOL;
  },
};
import {StakingInfo} from "Typings/Api/Stakings";

export interface NftCardInfo {
  nft_id: number,
  nft_address: string,
  meta_url: string,
  status: 'READY' | 'PENDING' | 'STAKING',
  staking_id?: StakingInfo['staking_id']
}

export interface NftMetaInfo {
  description: string,
  image: string,
  name: string,
  attributes: Array<{ value: string, trait_type: string }>
}

export type NftAddressData = {
  mint: string,
  drop: number,
  multiplier: number,
  family?: string,
  universe: string | 'EMPTY_UNIVERSE',
}

export const defaultNftData = {
  drop: 0,
  multiplier: 1,
  universe: 'EMPTY_UNIVERSE',
}
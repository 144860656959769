import {createContext, useContext} from 'react';
import {NftCardInfo} from "Typings/Components/NftCard";

export interface NftListContextState {
  staked: NftCardInfo[] | undefined;
  setStaked: (list: NftCardInfo[] | undefined) => void;
  pending: NftCardInfo[] | undefined;
  setPending: (list: NftCardInfo[] | undefined) => void;
  ready: NftCardInfo[] | undefined,
  setReady: (list: NftCardInfo[] | undefined) => void;
}

export const NftListContext = createContext<NftListContextState>({} as NftListContextState);

export function useTokenLists(): NftListContextState {
  return useContext(NftListContext);
}

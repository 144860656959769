import React, {useCallback, useEffect, useState} from "react";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {useWallet} from "@solana/wallet-adapter-react";
import {signThatShit} from "Utils/Solana/publicKey";
import {AuthApi} from "Api/Auth";
import {COLORS} from "Constants/theme";
import {useAuthContext} from "Store/AuthContext/useAuthContext";


interface WalletButtonProps {
  className?: string
}

const WalletButton = ({className}: WalletButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signedMessage, setSignedMessage] = useState('');
  const {signMessage, wallet} = useWallet();
  const {userInfo, isLoggedIn, setIsLoggedIn, logout} = useAuthContext();
  const adapter = wallet?.adapter;

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isHovering && (e.target as Element).classList.contains('wallet-adapter-button')) setIsHovering(true)
    if (isHovering && !(e.target as Element).classList.contains('wallet-adapter-button')) setIsHovering(false)
  }, [isHovering, setIsHovering]);
  const handleMouseLeave = useCallback(() => setIsHovering(false), [setIsHovering]);
  const handleMouseClick = useCallback(async () => {
    await adapter?.connect();
    if (adapter?.connected && signMessage && !loading && !signedMessage && !isLoggedIn) {
      const wallet_address = adapter.publicKey;
      setLoading(true)
      try {
        const code = 'By signing you confirm the connection with metabuildersclub.io.';
        const signature = await signThatShit(wallet_address, signMessage, code)
        // @ts-ignore
        setSignedMessage(signature)

        try {
          const api = new AuthApi();
          await api.login({code}, {wallet_address, signature})
          setIsLoggedIn(true)
        } catch (e) {
          setIsLoggedIn(false);
          if (adapter.connected) await adapter.disconnect()
          await logout();
          // @ts-ignore
          console.log(e?.message)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(false)
    }
  }, [loading, signMessage, adapter, isLoggedIn, logout, setIsLoggedIn, signedMessage])

  useEffect(() => {
    (async () => {
      if (adapter && !adapter.connected) await adapter.connect();
    })()
  }, [adapter])

  let title = '...';

  if (!wallet) title = 'Select Wallet';
  if (wallet && !isLoggedIn) title = 'Connect Wallet';
  if (loading && !isLoggedIn && !userInfo) title = 'Verifying...';

  return (
    <div
      className={className}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <WalletMultiButton
        style={{
          backgroundColor: isHovering ? COLORS.textColor : "transparent",
          border: `1px solid ${COLORS.textColor}`,
          color: isHovering ? COLORS.primary : COLORS.textColor,
          padding: '5px 20px',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          height: 'auto',
          borderRadius: '2rem',
          transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
          backgroundImage: 'none',
        }}
        startIcon={undefined}
        onClick={handleMouseClick}
      >{title}</WalletMultiButton>
    </div>
  )
}

export default WalletButton;
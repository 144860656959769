import React, {MouseEventHandler} from 'react';
import {logoutIcon} from "Assets/Icons";
import {useAuthContext} from "Store/AuthContext/useAuthContext";

import 'Styles/ProfileBtn.scss';


function ProfileBtn() {
  const {userInfo, logout} = useAuthContext();
  return (
    <div className="d-flex align-items-center">
      <span className="d-block me-4">
        { userInfo?.wallet_address?.replace(/^(\w{5}).+(\w{5})$/, '$1...$2') || '...' }
      </span>
      <button
        id="profile-menu-button"
        className="btn btn-secondary-outlined"
        onClick={logout as MouseEventHandler}
        title="Logout"
      >
        { logoutIcon }
      </button>
    </div>
  )
}

export default ProfileBtn;
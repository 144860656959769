import React from 'react';
import {discordIcon, twitterIcon} from "Assets/Icons";
import WalletButton from "Components/WalletButton";
import ProfileBtn from "Components/ProfileBtn";
import {useWallet} from "@solana/wallet-adapter-react";
import {discord, twitter} from "Constants/socialLinks";
import {useAuthContext} from "Store/AuthContext/useAuthContext";

function PageHeader() {
  const {isLoggedIn} = useAuthContext();
  const {wallet} = useWallet();
  const isConnected = isLoggedIn && wallet?.adapter?.connected;

  return (
    <header className="navbar py-1 position-relative bg-violet">
      <div className="container my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="navbar__title"><b>MetaBuilders</b></div>

          <div className="d-flex align-items-center">
            <div className="navbar__social-icon-wrapper d-none d-sm-block" style={{marginRight: 20}}>
              <a href={discord} className="navbar__social-icon" style={{marginRight: 10}} target="_blank" rel="noreferrer">{discordIcon}</a>
              <a href={twitter} className="navbar__social-icon" target="_blank" rel="noreferrer">{twitterIcon}</a>
            </div>
            {!isConnected
              ? <WalletButton />
              : <ProfileBtn />
            }
          </div>
        </div>
      </div>
    </header>
  )
}

export default PageHeader;
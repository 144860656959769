import {useMemo} from "react";
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import {clusterApiUrl} from "@solana/web3.js";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {PhantomWalletAdapter} from '@solana/wallet-adapter-wallets';
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui';

import "Styles/App.scss";
import Home from "Pages/Home";
import Verify from "Pages/Verify";
import {AuthProvider} from "Store/AuthContext/AuthProvider";
import {AuthContext} from "Store/AuthContext/useAuthContext";
import {NftListProvider} from "Store/NftListContext/NftListProvider";
import {Buffer} from 'buffer';

require('@solana/wallet-adapter-react-ui/styles.css');

// @ts-ignore
window.Buffer = Buffer;

const urlParams = new URLSearchParams(window.location.search);
const codeFromUrl = urlParams.get('code');
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;

const App = () => {
  // Custom RPC endpoint.
  const endpoint = useMemo(() => rpcHost || clusterApiUrl(network), []);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <AuthProvider>
        <AuthContext.Consumer>
          {({isLoggedIn}) => (
            <WalletProvider
              wallets={wallets}
              autoConnect={isLoggedIn}
              children={
                <WalletModalProvider>
                  {codeFromUrl
                    ? <Verify code={codeFromUrl} />
                    : (
                      <AuthProvider>
                        <NftListProvider>
                          <Home />
                        </NftListProvider>
                      </AuthProvider>
                    )
                  }
                </WalletModalProvider>
              }
            />
          )}
        </AuthContext.Consumer>
      </AuthProvider>
    </ConnectionProvider>
  );
};

export default App;

import {BaseApi} from "Api/BaseApi";
import {AxiosRequestConfig} from "axios";

import {
    UserInfo as Data
} from 'Typings/Api/Users';
import {
    createExtractInterceptor,
    createExtractErrorInterceptor
} from "Api/interceptors";
import {
    BaseApiReturnValue as BR,
} from "Typings/common";

export class UsersApi extends BaseApi {
    protected readonly endpoint = 'users';
    protected readonly endpointPrefix = 'v1';

    protected setInterceptors() {
        this.http.interceptors.response.use(
            createExtractInterceptor(),
            createExtractErrorInterceptor(),
        )
    }

    public current(config?: AxiosRequestConfig) {
        const path = '/me'
        const [url] = this.getUrl(path)
        return this.request<BR<Data>>('GET', url, { ...config })
    }
}

import React, {ReactNode, useCallback, useState} from "react";
import LoadingSpinner from "Components/LoadingSpinner";
import {joinClasses} from "Utils";


interface LoadingButtonProps {
  onClick: () => Promise<any>,
  className?: string,
  style?: object,
  children?: ReactNode;
  disabled?: boolean
}

const LoadingButton: React.FC<LoadingButtonProps> = ({className, onClick, children, disabled, style}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = useCallback(async () => {
    if (isLoading || disabled) return;
    setIsLoading(true);
    try {
      await onClick();
    } catch (e) {
      setIsLoading(false);
    }
  }, [isLoading, setIsLoading, onClick, disabled]);

  return (
    <button className={joinClasses(["btn", className])} onClick={onClickHandler} disabled={disabled} style={style}>
      {isLoading ? <LoadingSpinner /> : children}
    </button>
  )
}

export default LoadingButton;
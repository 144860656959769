import {BaseApi} from "Api/BaseApi";
import {AxiosRequestConfig} from "axios";

import {
  StakingInfo as Data,
} from 'Typings/Api/Stakings';
import {
  createExtractInterceptor,
  createExtractErrorInterceptor
} from "Api/interceptors";
import {UserInfo} from "Typings/Api/Users";
import {
  BaseApiReturnValue as BR,
  BaseApiReturnValueCollection as BRC,
} from "Typings/common";

export class StakingsApi extends BaseApi {
  protected readonly endpoint = 'stakings';
  protected readonly endpointPrefix = 'v1';

  protected setInterceptors() {
    this.http.interceptors.response.use(
      createExtractInterceptor(),
      createExtractErrorInterceptor(),
    )
  }

  public getList(user_id: UserInfo["user_id"], config?: AxiosRequestConfig) {
    const path = ''
    const [url] = this.getUrl(path)
    return this.request<BRC<Data>>('GET', url, {params: {user_id}, ...config})
  }

  public create(nft_address: string, config?: AxiosRequestConfig) {
    const path = ''
    const [url] = this.getUrl(path)
    return this.request<BR<Data>>('POST', url, {data: {nft_address}, ...config})
  }

  public createMulti(nfts_addresses: string[], config?: AxiosRequestConfig) {
    const path = '/bulk'
    const [url] = this.getUrl(path)
    return this.request<BR<Data>>('POST', url, {data: {nfts_addresses}, ...config})
  }

  public unstake(staking_id: Data['staking_id'], config?: AxiosRequestConfig) {
    const path = '/:staking_id'
    const [url] = this.getUrl(path, {staking_id})
    return this.request<BRC<Data>>('DELETE', url, {...config})
  }

  public unstakeMulti(stakings_ids: Data['staking_id'][], config?: AxiosRequestConfig) {
    const path = '/bulk'
    const [url] = this.getUrl(path)
    return this.request<BRC<Data>>('DELETE', url, {data: {stakings_ids}, ...config})
  }

  public count(user_id?: UserInfo["user_id"], config?: AxiosRequestConfig) {
    const path = '/count'
    const [url] = this.getUrl(path)
    return this.request<BR<never>>('GET', url, {params: {user_id, state: 'STAKING'}, ...config})
  }
}

import React, {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import {AuthContext} from 'Store/AuthContext/useAuthContext';
import {UserInfo} from "Typings/Api/Users";
import Cookie from "Utils/Cookie";
import {AuthApi} from "Api/Auth";
import {UsersApi} from "Api/Users";

export interface AuthProviderProps {
  children: ReactNode;
}

const cookie = new Cookie();
const isCookieSet = typeof cookie.get('mtbsession') === 'string';

export const AuthProvider: FC<AuthProviderProps> = ({children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isCookieSet);
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const logout = useCallback(async () => {
    if (!isLoggedIn) return;
    const api = new AuthApi();
    api.logout()
      .catch(console.error)
      .then(async () => {
        cookie.remove('mtbsession');
        setUserInfo(undefined);
        setIsLoggedIn(false);
      })
  }, [isLoggedIn, setUserInfo, setIsLoggedIn])

  useEffect(() => {
    (async () => {
      if (isLoggedIn && typeof userInfo === 'undefined') {
        const api = new UsersApi();
        const {data: userInfo} = await api.current()
        setUserInfo(userInfo as UserInfo)
      }
    })()
  }, [isLoggedIn, userInfo, setUserInfo])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn, setIsLoggedIn,
        userInfo, setUserInfo,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

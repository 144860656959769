import React, {useEffect, useState} from 'react';
import {useAnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {signThatShit} from "Utils/Solana/publicKey";
import {AuthApi} from "Api/Auth";
import {useWalletModal} from "@solana/wallet-adapter-react-ui";
import {useAuthContext} from "Store/AuthContext/useAuthContext";

interface VerifyProps {
  code: string,
}

const Verify = ({code}: VerifyProps) => {
  const [loading, setLoading] = useState(false);
  const {signMessage, wallet} = useWallet();
  const {visible, setVisible} = useWalletModal();
  const aWallet = useAnchorWallet();
  const {logout} = useAuthContext();
  const adapter = wallet?.adapter;

  useEffect(() => {
    (async () => {
      if (code && !visible && !adapter?.connected) {
        if (logout) await logout();
        setVisible(true)
      }
    })()
  }, [code, visible, adapter?.connected, setVisible, logout]);

  useEffect(() => {
    (async () => {
      if (code && adapter && !adapter.connected) {
        try {
          await adapter.connect();
          setVisible(false)
        } catch (e) {
          await adapter.disconnect();
        }
      }
    })()
  }, [code, adapter, setVisible]);

  useEffect(() => {
    (async () => {
      if (aWallet && adapter?.connected && signMessage && !loading) {
        const wallet_address = aWallet.publicKey;
        setLoading(true)
        try {
          const signature = await signThatShit(wallet_address, signMessage, code)
          const api = new AuthApi();
          await api.login({code}, {wallet_address, signature})

          window.location.replace('https://metabuildersclub.io');
        } catch (e) {
          setVisible(true);
          setLoading(false)
          if (adapter.connected) await adapter.disconnect();
          console.log(e)
        }
      }
    })();
  }, [code, setVisible, loading, signMessage, adapter, aWallet]);

  return <></>;
}

export default Verify;
import {BaseApi} from "Api/BaseApi";
import {BaseApiParams} from "Typings/common";
import {AxiosRequestConfig} from "axios";

export class AuthApi extends BaseApi {
    protected readonly endpoint = 'auth';
    protected readonly endpointPrefix = 'v1';

    public login<P extends BaseApiParams>(params: P, data: P, config?: AxiosRequestConfig) {
        const path = '/login'
        const [url] = this.getUrl(path)
        return this.request<never>('POST', url, { data, params, ...config })
    }

    public logout(config?: AxiosRequestConfig) {
        const path = '/logout'
        const [url] = this.getUrl(path)
        return this.request<never>('POST', url, { ...config })
    }
}

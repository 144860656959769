import React from 'react';

import 'Styles/PageFooter.scss';
import {joinClasses} from "Utils";
import {discordIcon, twitterIcon} from "Assets/Icons";
import {discord, twitter} from "Constants/socialLinks";

interface PageFooterProps {
  className?: string,
  style?: object
}

function PageFooter({style, className}: PageFooterProps) {
  return (
    <footer className={joinClasses(['page-footer d-flex flex-column align-items-center justify-content-end', className])} style={style}>
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center justify-content-center">
            <a href={discord} className="page-footer__social-icon" style={{ marginRight: 10 }}>{ discordIcon }</a>
            <a href={twitter} className="page-footer__social-icon">{ twitterIcon }</a>
          </div>
          <p className="page-footer__copyright">MetaBuilders</p>
        </div>
      </div>
    </footer>
  )
}

export default PageFooter;
import React from "react";
import PageHeader from "Components/PageHeader";
import PageFooter from "Components/PageFooter";
import NftList from "Components/NftList";
import PromoBlock from "Components/PromoBlock";
import {useWallet} from "@solana/wallet-adapter-react";
import {useAuthContext} from "Store/AuthContext/useAuthContext";


const Home = () => {
  const {isLoggedIn} = useAuthContext();
  const {wallet} = useWallet();
  const isConnected = isLoggedIn && wallet?.adapter?.connected;

  return (
    <>
      <PageHeader />
      <main className="bg-violet flex-grow-1 d-flex flex-column justify-content-between">
        <PromoBlock />
        <NftList innerStyle={isConnected ? {paddingBottom: 300} : undefined} />
      </main>

      {isConnected ? <PageFooter className="position-relative" style={{marginTop: -160}} /> : null}
    </>
  );
};

export default Home;
import { AxiosResponse, AxiosError } from 'axios'

import {
    BaseApiResponse as BR,
    BaseApiReturnValue as BRV,
} from 'Typings/common';


export const createExtractInterceptor = <T = any>() => (
  response: AxiosResponse<T>,
) => response?.data ?? response;

export const createExtractErrorInterceptor = <T = BRV<any>>() => (
    response: AxiosResponse<T>|AxiosError<T>,
) => {
    if (response instanceof AxiosError) return Promise.reject(response);
    if (!response?.data) return response;
    const { is_success, data, error, meta } = response.data as unknown as BR<any>;
    if (!is_success) return Promise.reject(error)
    else return { data, meta };
}
import React, {FC, ReactNode, useState} from 'react';
import {NftListContext} from 'Store/NftListContext/useTokenLists';
import {NftCardInfo} from "Typings/Components/NftCard";

export interface NftListProviderProps {
  children: ReactNode;
}

export const NftListProvider: FC<NftListProviderProps> = ({children}) => {
  const [staked, setStaked] = useState<NftCardInfo[]>();
  const [pending, setPending] = useState<NftCardInfo[]>();
  const [ready, setReady] = useState<NftCardInfo[]>();

  return (
    <NftListContext.Provider
      value={{
        staked, setStaked,
        pending, setPending,
        ready, setReady
      }}
    >
      {children}
    </NftListContext.Provider>
  );
};
